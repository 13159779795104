import React, { useEffect, useState, useCallback } from "react";

import classnames from "classnames";

// hooks
import {
  getQuickRepliesList,
  deleteQuickReplies
} from "../../api/index";

// router
import { useSearchParams } from "react-router-dom";

// component
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Tooltip, Modal, Input } from 'antd';
import {
  DeleteTwoTone,
  EditFilled,
  SearchOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import AddQuickReply from "./AddQuickReply/index";
import { toast } from "react-toastify";

interface IndexProps { }

const Index = (props: IndexProps) => {
  const { confirm } = Modal;

  const [searchParams] = useSearchParams();
  const channelId = searchParams.get('channelId');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [quickRepliesList, setQuickRepliesList] = useState<any>(null);
  const [messageSelected, setMessageSelected] = useState<any>(null);
  const [listQuickReplies, setlistQuickReplies] = useState<any[]>([]);
  const [valueInput, setValueInput] = useState<string>('');

  const handleScrollToBottom = useCallback(() => {
    const element = document.getElementById("quickReplies-list");
    if (element) {
      const { scrollTop, clientHeight, scrollHeight } = element;
      if ((scrollTop + clientHeight + 1 >= scrollHeight) && quickRepliesList?.total > quickRepliesList?.items?.length) {
        getQuickRepliesListData({
          channelIds: channelId || '',
          page: quickRepliesList?.page + 1,
          limit: 50,
        });
      }
    }
  }, [quickRepliesList, channelId]);

  useEffect(() => {
    const element = document.getElementById("quickReplies-list");
    if (element) {
      element.addEventListener("scroll", handleScrollToBottom);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScrollToBottom);
      }
    };
  }, [handleScrollToBottom]);

  useEffect(() => {
    if (channelId) {
      getQuickRepliesListData({
        channelId: channelId || '',
        page: 1,
        limit: 50,
      });
    }
  }, [channelId]);

  const getQuickRepliesListData = async (data: any) => {
    try {
      const response: any = await getQuickRepliesList({ channelId: data.channelId, page: data?.page, limit: data?.limit });
      if (response?.data?.items) {
        const arr = quickRepliesList?.items && data.page !== 1 ? [...quickRepliesList?.items, ...response.data.items] : response.data.items;
        setQuickRepliesList({
          total: response.data.total,
          page: data.page,
          items: arr,
        });
        if (valueInput === '') {
          setlistQuickReplies(arr);
        } else {
          setlistQuickReplies(arr?.filter((item: any) => item.name.toLowerCase().includes(valueInput.toLowerCase())));
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const onOpen = () => {
    setIsOpenModal(true);
  };

  const onOpenUpdate = (quickReplies: any) => {
    setMessageSelected(quickReplies);
    setIsOpenModal(true);
  };

  const onClose = () => {
    setIsOpenModal(false);
    setMessageSelected(null);
  };

  const onSuccess = () => {
    setIsOpenModal(false);
    setMessageSelected(null);
    getQuickRepliesListData({
      channelId: channelId || '',
      page: 1,
      limit: 50,
    });
  };

  const onDelete = async (quickRepliesId: string) => {
    try {
      const response: any = await deleteQuickReplies(quickRepliesId);
      if (response?.data && response?.success) {
        getQuickRepliesListData({
          channelId: channelId || '',
          page: 1,
          limit: 50,
        });
        toast.success('Xóa thành công');
      } else {
        toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const searchQuickRepliess = (e: any) => {
    const value = e.target.value;
    setValueInput(value);
    if (value === '') {
      setlistQuickReplies(quickRepliesList?.items);
    } else {
      setlistQuickReplies(quickRepliesList?.items?.filter((item: any) =>
        item.shortcut.toLowerCase().includes(value.toLowerCase()) ||
        item.messages?.some((message: any) => message.message.toLowerCase().includes(value.toLowerCase())
      )));
    }
  };

  return (
    <div
      className={classnames("user-chat", "w-100", "overflow-hidden")}
      id="user-chat"
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div className="chat-content p-3 p-lg-4 w-100 h-100">
        <div style={{
          marginBottom: 24
        }}>
          <span className="username" style={{ fontWeight: '500', fontSize: 18 }}>Hỗ trợ trả lời</span>
        </div>
        <div className="p-3 bg-white" style={{ borderRadius: 8 }}>
          <div style={{
            marginBottom: 24,
            borderBottom: '1px solid #d0d0d0',
          }}>
            <span className="username" style={{ fontSize: 15, color: localStorage.getItem('colorTheme') || '#4eac6d' }}>Trả lời nhanh</span>
            <div style={{
              marginTop: 16,
              width: 88,
              height: 2,
              backgroundColor: localStorage.getItem('colorTheme') || '#4eac6d',
            }} />
          </div>
          <Row className="align-items-center">
            <Col sm={4}>
              <Input
                placeholder="Tìm kiếm tin nhắn"
                prefix={<SearchOutlined />}
                onChange={searchQuickRepliess}
                value={valueInput}
              />
            </Col>
            <Col sm={8}>
              <ul className="list-inline user-chat-nav text-end mb-0">
                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                  <div
                    onClick={onOpen}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: localStorage.getItem('colorTheme') || '#4eac6d',
                      padding: "5px 10px",
                      borderRadius: 5,
                      height: 32,
                    }}
                  >
                    <span style={{ marginLeft: 4, color: 'white' }}>Thêm mẫu</span>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
          {isOpenModal && (
            <AddQuickReply
              isOpen={isOpenModal}
              onClose={onClose}
              onSuccess={onSuccess}
              channelSelected={channelId || ''}
              messageSelected={messageSelected}
            />
          )}
          <div
            className="bg-white"
            id="quickReplies-list"
            style={{
              borderRadius: 8,
              marginTop: 24,
              height: 'calc(100vh - 320px)',
              overflowY: 'auto',
              overflowX: 'hidden',
              border: '1px solid #d0d0d0'
            }}>
            <Row className="align-items-center p-3" style={{
              borderBottom: '1px solid #d0d0d0',
            }}>
              <Col sm={1}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>STT</span>
                </div>
              </Col>
              <Col sm={3} style={{
                borderLeft: '1px solid #d0d0d0'
              }}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Kí tự tắt</span>
                </div>
              </Col>
              <Col sm={8} style={{
                borderLeft: '1px solid #d0d0d0'
              }}>
                <div>
                  <span style={{ fontWeight: 'bold' }}>Tin nhắn</span>
                </div>
              </Col>
            </Row>
            {
              listQuickReplies?.length > 0 ? (
                listQuickReplies?.map((quickReplies: any, key: number) => (
                  <Row key={key} className="btn-tag align-items-center p-3" style={{
                    borderBottom: '1px solid #d0d0d0',
                    height: 60,
                  }}>
                    <Col sm={1}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <span>{key + 1}</span>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <span>{quickReplies?.shortcut}</span>
                      </div>
                    </Col>
                    <Col sm={8} style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                      <div style={{
                        width: 'calc(100% - 60px)',
                      }}>
                        {
                          quickReplies?.messages?.map((message: any, index: number) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              }}>
                              {
                                message?.photos?.length > 0 ? (
                                  <div style={{
                                    position: 'relative',
                                    width: 24,
                                    height: 24,
                                    marginRight: 8,
                                    borderRadius: 6,
                                  }}>
                                    <img src={message?.photos[0]?.location} style={{
                                      width: 24,
                                      height: 24,
                                      borderRadius: 6,
                                    }} />
                                    {
                                      message?.photos?.length > 1 ? (
                                        <Tooltip title={(
                                          <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          }}>
                                            {
                                              message?.photos?.map((photo: any, index: number) => (
                                                <img
                                                  key={index}
                                                  src={photo?.location}
                                                  style={{
                                                    width: 24,
                                                    height: 24,
                                                    borderRadius: 6,
                                                    marginLeft: index == 0 ? 0 : 8,
                                                  }}
                                                />
                                              ))

                                            }
                                          </div>
                                        )}>
                                          <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: 24,
                                            height: 24,
                                            marginRight: 8,
                                            borderRadius: 6,
                                            backgroundColor: '#0000004d',
                                          }}>
                                            <span style={{ color: 'white' }}>+{message?.photos?.length - 1}</span>
                                          </div>
                                        </Tooltip>
                                      ) : null
                                    }
                                  </div>
                                ) : null
                              }
                              {
                                message?.files?.length > 0 ? (
                                  <Tooltip title={
                                    message?.files?.map((photo: any, index: number) => (
                                      <div key={index}>{photo?.originalname}</div>
                                    ))
                                  }>
                                    <div style={{
                                      width: 14,
                                      height: 14,
                                      position: 'relative',
                                      marginRight: 16,
                                    }}>
                                      <PaperClipOutlined
                                        style={{ fontSize: 14 }}
                                      />
                                      <div style={{
                                        fontSize: message?.files?.length > 99 ? 7 : 10,
                                        position: 'absolute',
                                        top: -6,
                                        right: -9,
                                        width: 16,
                                        height: 16,
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontWeight: 'bold',
                                        border: '1px solid white',
                                      }}>
                                        {
                                          message?.files?.length > 99 ?
                                            "99+" :
                                            message?.files?.length
                                        }
                                      </div>
                                    </div>
                                  </Tooltip>
                                ) : null
                              }
                              <Tooltip title={message?.message}>
                                <span style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}>{message?.message}</span>
                              </Tooltip>
                            </div>
                          ))
                        }
                      </div>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                        <div
                          className="btn-delete-tag"
                          onClick={() => onOpenUpdate({ ...quickReplies, indexQuickReplies: key + 1 })}
                        >
                          <EditFilled
                            style={{
                              fontSize: 20,
                              marginRight: 16,
                            }}
                          />
                        </div>
                        <div
                          className="btn-delete-tag"
                          onClick={() => onDelete(quickReplies?.id)}
                        >
                          <DeleteTwoTone
                            twoToneColor='red'
                            style={{
                              fontSize: 20,
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                ))
              ) : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
