import React, { useState } from "react";

// component
import iconFb from "../../../assets/images/users/ic_fb.png";
import iconCategory from "../../../assets/images/users/ic_category.png";

interface IndexProps {
    pageList: any[];
    total: any
}

const Index = (props: IndexProps) => {
    const { pageList, total } = props;
    const [codeSelected, setCodeSelected] = useState("all");

    return (
        <div className="p-3 bg-white" style={{ borderRadius: 8, marginTop: 16 }}>
            <ul className="list-inline user-chat-nav mb-0">
                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                    <div
                        onClick={() => setCodeSelected("all")}
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            // backgroundColor: codeSelected == 'all' ? '#0000ff14' : 'white',
                            border: codeSelected == 'all' ? `1px solid ${localStorage.getItem('colorTheme') || '#4eac6d'}` : '1px solid #d0d0d0',
                            padding: "5px 10px",
                            borderRadius: 5,
                            height: 40,
                        }}
                    >
                        <img
                            src={iconCategory}
                            alt=""
                            style={{ width: 20, height: 20, marginRight: 8 }}
                        />
                        <span style={{ fontWeight: 'bold', marginRight: 40 }}>Tất cả</span>
                        <span className={`badge ${codeSelected == 'all' ? 'bg-primary' : 'bg-secondary'} rounded-pill ms-2`}>{total}</span>
                    </div>
                </li>
                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                    <div
                        onClick={() => setCodeSelected("facebook")}
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: codeSelected == 'facebook' ? '#0000ff14' : 'white',
                            border: codeSelected == 'facebook' ? '1px solid blue' : '1px solid #d0d0d0',
                            padding: "5px 10px",
                            borderRadius: 5,
                            height: 40,
                        }}
                    >
                        <img
                            src={iconFb}
                            alt=""
                            style={{ width: 20, height: 20, marginRight: 8 }}
                        />
                        <span style={{ fontWeight: 'bold', marginRight: 40 }}>Facebook</span>
                        <span className={`badge ${codeSelected == 'facebook' ? 'bg-primary' : 'bg-secondary'} rounded-pill ms-2`}>{total}</span>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Index;
