/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import classnames from "classnames";

// router
import { useSearchParams } from "react-router-dom";

// component
import {
    UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Menu, Space, Tabs, } from 'antd';
import { getReportInteract } from "../../../api/statistic";
import dayjs, { Dayjs } from 'dayjs';
import { createSelector } from "reselect";
import { useRedux } from "../../../hooks/useRedux";
import InteractByStaff from "./InteractByStaff";
import InteractByTime from "./InteractByTime";
import _ from 'lodash';
import DateTimeRangePickerComponent from "../../../components/RangePicker/DateTimeRangePickerComponent";
import { add, endOfDay, parseISO, startOfDay } from "date-fns";


interface IndexProps { }
type ColumnVisibility = {
    oldCustomerCount: boolean;
    newCustomerCount: boolean;
    totalCustomerCount: boolean;
    customerInboxCount: boolean;
    customerCommentCount: boolean;
    newConversationCount: boolean;
    orderNewCustomerCount: boolean;
    orderOldCustomerCount: boolean;
    totalOrderCount: boolean;
    totalOrderConversionRate: boolean;
    newCustomerConversionRate: boolean;
    oldCustomerConversionRate: boolean;
};

const Inbox = (props: IndexProps) => {
    const { dispatch, useAppSelector } = useRedux();
    const dateToDayjs = (date: any) => dayjs(date);

    const [searchParams] = useSearchParams();
    const [filterStyle, setFilterStyle] = useState<string>('day');
    const [interactByTimeData, setInteractByTimeData] = useState<any>(null);
    const [interactByUserData, setInteractByUsereData] = useState<any>(null);
    const groupId = searchParams.get('groupId');
    const channelId = searchParams.get('channelId');
    const [channelIds, setChannelIds] = useState<any>(null);

    const initialStartDate = dateToDayjs(startOfDay(new Date()));
    const [startDate, setStartDate] = useState<Dayjs>(dateToDayjs(initialStartDate));

    const initialEndDate = dateToDayjs(endOfDay(new Date()));
    const [endDate, setEndDate] = useState<Dayjs>(dateToDayjs(initialEndDate));
    
    const [visibleColumns, setVisibleColumns] = useState<ColumnVisibility>({
        oldCustomerCount: true,
        newCustomerCount: true,
        totalCustomerCount: true,
        customerInboxCount: true,
        customerCommentCount: true,
        newConversationCount: true,
        orderNewCustomerCount: true,
        orderOldCustomerCount: true,
        totalOrderCount: true,
        totalOrderConversionRate: true,
        newCustomerConversionRate: true,
        oldCustomerConversionRate: true,
    });

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleColumnVisibilityChange = (checkedValues: string[]) => {
        setVisibleColumns({
            oldCustomerCount: checkedValues.includes('oldCustomerCount'),
            newCustomerCount: checkedValues.includes('newCustomerCount'),
            totalCustomerCount: checkedValues.includes('totalCustomerCount'),
            customerInboxCount: checkedValues.includes('customerInboxCount'),
            customerCommentCount: checkedValues.includes('customerCommentCount'),
            newConversationCount: checkedValues.includes('newConversationCount'),
            orderNewCustomerCount: checkedValues.includes('orderNewCustomerCount'),
            orderOldCustomerCount: checkedValues.includes('orderOldCustomerCount'),
            totalOrderCount: checkedValues.includes('totalOrderCount'),
            totalOrderConversionRate: checkedValues.includes('totalOrderConversionRate'),
            newCustomerConversionRate: checkedValues.includes('newCustomerConversionRate'),
            oldCustomerConversionRate: checkedValues.includes('oldCustomerConversionRate'),
        });
    };

    const menu = (
        <Menu >
            <Menu.Item >
                <Checkbox.Group
                    defaultValue={Object.keys(visibleColumns).filter(key => visibleColumns[key as keyof ColumnVisibility])}
                    onChange={handleColumnVisibilityChange}
                >
                    <Space direction="vertical" onClick={(e: any) => e.stopPropagation()}>
                        <div style={{fontWeight:500}}>Dữ liệu từ Web</div>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="oldCustomerCount">Tương tác khách cũ</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="newCustomerCount">Tương tác khách mới</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="totalCustomerCount">Tổng tương tác</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="customerInboxCount">Tương tác tin nhắn</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="customerCommentCount">Tương tác bình luận</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="newConversationCount">Hội thoại tin nhắn mới</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="orderNewCustomerCount">Đơn khách mới</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="orderOldCustomerCount">Đơn khách cũ</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="totalOrderCount">Tổng đơn</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="totalOrderConversionRate">Tỉ lệ chuyển đổi tổng đơn hàng</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="newCustomerConversionRate">Tỉ lệ chuyển đổi khách hàng mới</Checkbox>
                        <Checkbox style={{color:'rgba(0, 0, 0, .65)'}} value="oldCustomerConversionRate">Tỉ lệ chuyển đổi khách hàng cũ</Checkbox>
                    </Space>
                </Checkbox.Group>
            </Menu.Item>
        </Menu>
    );

    const groupDataInfo = createSelector(
        (state: any) => state.Chats,
        (state) => ({
            groupInfo: state.groupInfo,
        })
    );
    const { groupInfo } = useAppSelector(groupDataInfo);
    useEffect(() => {
        setChannelIds(groupId ? groupInfo?.channelIds?.toString() : channelId)

    }, [groupId, channelId, groupInfo]);

    useEffect(() => {
        if (channelIds) {
            getInboxData('day');
        }
    }, [channelIds]);

    const getInboxData = async (filterStyle: String) => {
        try {
            const params = {
                // convert to UTC+7
                startTime: add(startDate.toDate(), { hours: 7 }).toISOString(),
                endTime: add(endDate.toDate(), { hours: 7 }).toISOString(),
                channelIds: groupId ? groupInfo?.channelIds?.toString() : channelId,
                period: filterStyle,
            }
            const response: any = await getReportInteract(params);
            if (response?.data) {
                setInteractByTimeData( _.orderBy(response?.data?.engagements, ['time'], ['desc']) || [])
                setInteractByUsereData(response?.data?.usersEngagements || [])
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleSubmitFilter = async (startDate:Dayjs,endDate:Dayjs) => {
        try {
            const params = {
                // convert to UTC+7
                startTime: add(startDate.toDate(), { hours: 7 }).toISOString(),
                endTime: add(endDate.toDate(), { hours: 7 }).toISOString(),
                channelIds: groupId ? groupInfo?.channelIds?.toString() : channelId,
                period: filterStyle,
            }
            const response: any = await getReportInteract(params);
            if (response?.data) {
                setInteractByTimeData(_.orderBy(response?.data?.engagements, ['time'], ['desc']) || [])
                setInteractByUsereData(response?.data?.usersEngagements || [])
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const renderOperations = () => {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 16
            }}>
                <div
                    style={{
                        padding: '5px 12px',
                        backgroundColor: filterStyle == 'day' ? (localStorage.getItem("colorTheme") ?? '#1677ff') : '#EAECF0',
                        borderRadius: '6px 0 0 6px',
                        fontWeight: '500',
                        color: filterStyle == 'day' ? 'white' : 'black',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setFilterStyle('day')
                        getInboxData('day')
                    }}
                >Theo ngày</div>
                <div
                    style={{
                        padding: '5px 12px',
                        backgroundColor: filterStyle == 'hour' ? (localStorage.getItem("colorTheme") ?? '#1677ff') : '#EAECF0',
                        fontWeight: '500',
                        color: filterStyle == 'hour' ? 'white' : 'black',
                        borderRadius: '0 6px 6px 0',
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setFilterStyle('hour')
                        getInboxData('hour')
                    }}
                >Theo giờ</div>
                <Dropdown overlay={menu} trigger={['click']}
                    open={dropdownVisible}
                    onOpenChange={setDropdownVisible}

                >
                    <div
                        style={{
                            marginLeft: 10,
                            backgroundColor: '#EAECF0',
                            fontWeight: '500',
                            borderRadius: 6,
                            cursor: 'pointer',
                        }}
                    >
                        <Button type="text"><UnorderedListOutlined style={{ marginRight: 8 }} />Thuộc tính</Button>
                    </div>
                </Dropdown>
            </div>
        );
    };

    const items = [
        {
            label: 'Theo thời gian',
            key: '1',
            children: <InteractByTime filterStyle={filterStyle} data={interactByTimeData?.length > 0 && interactByTimeData} visibleColumns ={visibleColumns}/>
        },
        {
            label: 'Theo nhân viên',
            key: '2',
            children: <InteractByStaff data={interactByUserData?.length > 0 && interactByUserData} visibleColumns ={visibleColumns} />
        },
    ];


    return (
        <div
            className={classnames("user-chat", "w-100", "overflow-hidden")}
            id="user-chat"
            style={{
                justifyContent: "center",
                display: "flex",
            }}
        >
            <div className="chat-content w-100 h-100" style={{ padding: '24px 0 24px 24px' }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 12,
                }}>
                    <span className="username" style={{ fontWeight: 'bold', fontSize: 18 }}>Tương tác</span>
                </div>
                <div style={{
                    borderRadius: 8,
                    padding: 16,
                    backgroundColor: 'white',
                    marginRight: 24,
                    marginBottom: 24,
                    display: 'flex',
                    justifyContent: 'end',
                }}>
                    <DateTimeRangePickerComponent 
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    onSubmit={handleSubmitFilter} />
                </div>
                <div style={{
                    height: 'calc(100vh - 200px)',
                    overflow: 'auto',
                }}>
                    <div style={{
                        borderRadius: 8,
                        padding: '16px 0 16px 16px',
                        backgroundColor: 'white',
                        marginRight: 24,
                        // minWidth: 1100
                    }}>
                        <div style={{
                            marginBottom: 24,
                        }}>
                            <div style={{
                                fontSize: 16,
                                fontWeight: '500',
                                marginBottom: 4,
                            }}>
                                Thống kê chi tiết tương tác
                            </div>
                            <div style={{
                                fontSize: 12,
                                color: '#666',
                            }}>
                                Thống kê chi tiết tương tác theo thời gian và nhân viên
                            </div>
                        </div>
                        <Tabs defaultActiveKey="1" tabBarExtraContent={renderOperations()} items={items} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Inbox;

